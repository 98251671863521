@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.div_delete_imovel:hover {
    cursor: pointer;
    background-color: rgb(216, 56, 56);
    color: white !important;

}

.div_link_imovel:hover {
    cursor: pointer;
    text-decoration: underline;

}

.popup_list {
    max-height: 900px;
    max-width: 500px;
    width: 100%;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0, 0, 0, 1.09);

}

.popup_list.hidden {
    opacity: 0;
    max-height: 0px;
    max-width: 100px;
}

.list_imoveis_icon {
    animation: fadeIn 0.4s ease-in-out;
}

html {
    overflow: hidden;
}

.icon_close{
    cursor: pointer;
}

@media (max-width: 520px) {
    .popup_list {
        width: calc(100vw - 30px);
    }

    .list_imoveis_share {
        width: calc(100vw - 30px);
    }
}